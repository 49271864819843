import BEM from 'tao-bem';
import Swiper, { Grid, Navigation, Pagination} from 'swiper';
import 'swiper/css/bundle';

class Clients extends BEM.Block {
	static get blockName() {
		return 'b-clients';
	}
	onInit() {
		new Swiper(".clientsSwiper", {
			slidesPerView: 2,
			spaceBetween: 32,
			modules: [Grid, Pagination, Navigation],
			pagination: {
				el: ".swiper-pagination",
				clickable: true,
			},
			navigation: {
				nextEl: ".swiper-next",
				prevEl: ".swiper-prev",
			},
			breakpoints: {
				320: {
					slidesPerView: 2,
					spaceBetween: 24,
					slidesPerGroup: 2,
					grid: {
						rows: 1,
						fill: 'row',
					},
				},
				640: {
					slidesPerView: 2,
					spaceBetween: 32,
					slidesPerGroup: 2,
					grid: {
						rows: 1,
					},
				},
				1000: {
					slidesPerView: 3,
					spaceBetween: 32,
					slidesPerGroup: 1,
					grid: {
						rows: 1,
					},
				}
			}
		});
	}
}

Clients.register();

export default Clients;